<template>
  <div class="ddj-country">
    <div class="main-Title bgff"><h2>Country</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <!-- <el-col :span="4">
              <el-form-item label="Code:"
                ><el-input
                  v-model="filter.countryCode"
                  placeholder="Please enter Code"
                  class="mr10"
                ></el-input></el-form-item>
            </el-col> -->
            <el-col :span="4">
              <el-form-item label="Country:">
                <el-select
                  v-model="filter.countryCode"
                  placeholder="Please select"
                  class="mr10"
                  filterable
                >
                  <el-option value="" label="---ALL---"></el-option>
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 	<el-col :span="3">
							<el-form-item label="OS:" label-width="50px">
								<el-select v-model="filter.os" placeholder="Please select OS" class="mr10">
									<el-option v-for="item in options.os" :key="item.value" :value="item.value" :label="item.label"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="上游名称:">
								<el-select v-model="filter.advertiserName" placeholder="Please select Advertiser" class="mr10">
									<el-option v-for="item in options.advertiser" :key="item.value" :value="item.value" :label="item.label"></el-option>
								</el-select>
							</el-form-item>
						</el-col> -->
            <el-col :span="2"
              ><el-button type="primary" size="mini" @click="getList('filter')">筛选</el-button>
              <el-button type="primary" size="mini" @click="add()">Add</el-button>
            </el-col>
            <!--          <el-col :span="2"></el-col> -->
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          height="70vmin"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="国家代码" prop="countryCode"></el-table-column>
          <el-table-column label="国家名称" prop="countryName"></el-table-column>
          <el-table-column label="config" prop="speedConfig"></el-table-column>
          <el-table-column label="操作" width="210" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjCountryCon from '../../controllers/DDJ/country';
  export default {
    name: 'ddjCountry',
    ...ddjCountryCon,
  };
</script>

<style></style>
